export default class ReactLobby {

  init() {

    window.rizkFunctions = function(type, data) {
      return Rizk.LobbyReactApi.getFunctionality(type, data)
    }

    window.platformFunction = function(event) {
      Rizk.LobbyV2.gameTileClick(event)
    };

    window.isFeatureActive = function(name) {
      return Rizk.isFeatureActive(name, RizkConfig);
    }

    window.isFeatureOn = function(name) {
      return Rizk.isFeatureOn(name, RizkConfig);
    }

    window.jackpotFunction = function() {
      Rizk.LobbyV2.testJackpot();
    };

    window.fetchJackpotFunction = function() {
      return new Promise((resolve, reject) => {
        try {
          if (Rizk.isFeatureActive('jp-ticker', RizkConfig)) {
            let url
            if (RizkConfig.logged === true) {
              url = '/jackpots/' + (RizkConfig.currency === 'HRK' ? 'EUR' : RizkConfig.currency);
            } else {
              url = '/jackpots/' + Rizk.localeCurrency[RizkConfig.locale];
            }

            ajax(
              url,
              function (x) {
                // console.log(x, x.status, x.response);
                if (x.status === 200) {
                  if (x.response !== null) {
                    // action(x);
                    resolve(x);
                  } else {
                    resolve(null);
                  }
                } else {
                  reject('Error fetching data');
                }
              },
              null,
              false,
              true,
              'application/json'
            );
          }
        } catch (error) {
          console.error('fetchJackpotFunction', error)
          reject(error);
        }
      });
    };

    window.racesFunction = function(fun) {
      Rizk.LobbyV2.testRaces(fun);
    };

    window.racesFormatBet = function(bet) {
      return Rizk.Race.formatBet(bet);
    };

    window.racesPrizes = function(id) {
      return Rizk.Race.prizes.get(id).prizes[0].prize;
    };

    window.getTranslation = function(transID) {
      const sessionData = window.sessionStorage.getItem(`trans-${RizkConfig.locale}`)
      const translations = sessionData ? JSON.parse(sessionData) : {};

      return translations[transID] || Rizk.Translations.get(transID, RizkConfig.locale) || transID;
    };

    window.fetchTranslations = async function(keys) {
      try {
        const translationsData = await Rizk.Translations.fetch(keys)
        return translationsData
      } catch (err) {
        console.error('fetchTranslations', err.message)
      }
    };

    window.getRaceImageUrl = function(templateId) {
      return Rizk.Race.getRaceImage(templateId);
    };

    window.RaceOptIn = async function(race) {
      return await Rizk.Race.optinButtonClick(race)
    }

    window.gameImg = function(id) {
        return getGameTileSrc(id);
    };

    window.raceModal = function(race, type) {
      if (type === 'modal') {
        Rizk.Race.createRaceGameModal(race);
      } else {
        Rizk.Race.optinPickGameEvent(race);
      }
    };

    window.getFavourites = function(fun) {
      return new Promise(function (resolve, reject) {
        ajax(
          '/get/favourites',
          function (x) {
            console.log(x, x.status, x.response)
            if (x.status === 200) {
              if (x.response !== null) {
                document.querySelector('#root').setAttribute('data-fav', x.response);
                fun();
              }
            } else {
              return null;
            }
          },
          null,
          false,
          true,
          'application/json'
        )
      });
    };

    window.fetchRecentWinners = async function(action) {
      return new Promise((resolve, reject) => {
        try {
          ajax(
            `/recent-winners/${RizkConfig.country.toUpperCase()}`,
            function (x) {
              if (x.status === 200) {
                if (x.response !== null) {
                  // If response is a JSON string, parse it
                  if (typeof x.response === 'string') {
                    try {
                      const jsonResponse = JSON.parse(x.response);
                      resolve(jsonResponse);
                    } catch (e) {
                      reject('Failed to parse JSON response');
                    }
                  } else {
                    // Otherwise, resolve directly
                    resolve(x.response);
                  }
                } else {
                  resolve(null);
                }
              } else {
                reject('Error fetching data');
              }
            },
            null, // No data to send in the body
            false, // Not using synchronous request
            true, // Cross-origin requests
            'application/json'
          );
        } catch (error) {
          reject(error);
        }
      });
    };

    window.formatCurrency = function(amount) {
      return intlFormatCurrency(amount);
    };

    window.fetchCurrentRaffles = function (fetchLatest) {
      return new Promise((resolve, reject) => {
        const url = '/get-current-raffles';

        const rafflesCallback = (xhr) => {
          if (xhr.status === 200) {
            const data = JSON.parse(xhr.responseText);
            console.log('Processed data:', data);
            resolve(data);
          } else {
            console.error('Error obtaining raffles:', xhr.statusText);
            reject(xhr.statusText);
          }
        }

        if (fetchLatest) {
          ajax(url, rafflesCallback, null, false, true, 'application/json');
        } else {
          ajaxCached(url, rafflesCallback, null, 30);
        }
      });
    }

    window.fetchFutureRaffles = function (fetchLatest) {
      return new Promise((resolve, reject) => {
        const url = '/get-next-raffles';

        const rafflesCallback = (xhr) => {
          if (xhr.status === 200) {
            const data = JSON.parse(xhr.responseText);
            console.log('Processed data:', data);
            resolve(data);
          } else {
            console.error('Error obtaining future raffles:', xhr.statusText);
            reject(xhr.statusText);
          }
        }

        if (fetchLatest) {
          ajax(url, rafflesCallback, null, false, true, 'application/json');
        } else {
          ajaxCached(url, rafflesCallback, null, 30);
        }
      });
    }

    window.getRaffleDetailsAndUpdateTicketsReact = function (raffleId, type) {
      return new Promise((resolve, reject) => {
        const userId = RizkConfig.userId;
        if (!userId || userId === 'logged-out') {
          console.log('No user ID found, skipping ticket update.');
          return;
        }

        const url = `/get-raffle-data/${raffleId}`;
        ajaxCached(url, function (xhr) {
          if (xhr.status === 200) {
            const response = JSON.parse(xhr.responseText);
            if (!response || Object.keys(response).length === 0) {
              console.log('Empty response received, skipping ticket update.');
              return;
            }
            console.log('Raffle details:', response);
            if (response.success && response.raffleData && response.raffleData.getAllRaffleUsers) {
              let userTicketData = response.raffleData.getAllRaffleUsers.find(holder => holder.userId === userId);
              resolve({
                raffleAmount: userTicketData ? userTicketData.raffleAmount : 0,
                type: type
              })
            } else {
              console.error('Error obtaining raffle details:', response.message);
              reject(response.message);
            }
          } else {
            console.error('AJAX Error:', xhr.statusText);
            reject(xhr.statusText);
          }
        }, null, 30);
      });
    }

    window.loginModalNoDemo = function (event) {
      RizkConfig.loginRedirectUrl = window.location.pathname;
      if (event.target.closest('a').hasAttribute('data-gameurl')) {
        RizkConfig.loginRedirectUrl = event.target.closest('a').getAttribute('data-gameurl')
      }
      event.preventDefault()
      showAjaxModal('login', true, { noDemoPlay: true }, showLoginCb)
    }

    window.updateDataParent = function (isLive) {
      const rootContainer = document.querySelector('#root');
      const dataParent = isLive ? 'live-casino' : 'casino';

      rootContainer.setAttribute('data-parent', dataParent)
      window.linksActive()
    }

    window.updateSEOContent = function (category, isLive) {
      const isMainpage = category === 'main-page';
      const mainPagePrefix = (isLive) ? 'livecasino' : 'casino';
      const mainContentPrefix = (isLive) ? 'livecasino' : 'casino.games';
      const prefix = (isLive) ? 'meta.live.lobby' : 'meta.lobby';

      const brandName = RizkConfig.brand.charAt(0).toUpperCase() + RizkConfig.brand.slice(1);

      const titleKey = (isMainpage) ? `${mainPagePrefix}.title` : `${prefix}.title.${category}`;
      const descriptionKey = (isMainpage) ? `${mainPagePrefix}.description` : `${prefix}.description.${category}`;
      const contentKey = (isMainpage) ? `${mainContentPrefix}.seo.content` : `${prefix}.content.${category}`;

      Rizk.Translations.get(titleKey, (title) => {
        const seoTitle = (title !== titleKey) ? title : brandName;
        document.title = seoTitle;
      });

      Rizk.Translations.get(descriptionKey, (description) => {
        const seoDescription = (description !== descriptionKey) ? description : brandName;

        const metaDescription = document.querySelector('meta[name="description"]');
        metaDescription.setAttribute('content', seoDescription);
      });
      
      Rizk.Translations.get(contentKey, (content) => {
        const seoContent = (content !== contentKey) ? content : '';

        const contentLength = seoContent.split(' ');
        const showMoreContent = document.querySelector('.seo-content-box__show-more');

        const seoBox = document.querySelector('#seo-content-box');

        const seoCheckbox = document.querySelector('#seo-checkbox');
        if (seoCheckbox) {
          seoCheckbox.checked = false;
        }

        if (seoContent.length > 0 && seoBox) {
          seoBox.style.display = 'block';
          seoBox.innerHTML = seoContent;

          if (showMoreContent) {
            showMoreContent.style.display = (contentLength.length > 400 ? 'flex' : 'none');
          }
        } else {
          seoBox.style.display = 'none';
          seoBox.innerHTML = '';

          if (showMoreContent) {
            showMoreContent.style.display = 'none';
          }
        }
      });
    }
  }
}
